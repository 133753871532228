var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0 ma-0 customer_confirm_page",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "wwwx",
    staticStyle: {
      "position": "absolute",
      "top": "1rem",
      "left": "1rem"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "alt": "CodeDrills Logo",
      "width": "108",
      "src": require('../../assets/images/CodeDrillsLight.svg')
    }
  })])], 1), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', {
    staticClass: "customer_confirm_carousel",
    attrs: {
      "elevation": "0",
      "height": "100%",
      "dark": ""
    }
  }, [_c('Carousel', {
    attrs: {
      "slides": _vm.slides
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "\n      rounded-tl-xl rounded-bl-xl\n      d-flex\n      flex-column\n      justify-center\n      customer_confirm_card\n    ",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ConfirmPassForm')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }